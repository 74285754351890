import { defineMessages } from 'react-intl';

export const thankYouPageMessages = defineMessages({
  thank_you: {
    id: 'nouns.thank_you'
  },  
  cta_feedback_positive: {
    id: 'button_actions.cta_feedback_positive'
  },  
  cta_feedback_negative: {
    id: 'button_actions.cta_feedback_negative'
  },  
  cta_done: {
    id: 'button_actions.cta_done'
  },
  cta_close: {
    id: "button_actions_cta_close",
  },
  feedback_text: {
    id: 'messages.feedback_text'
  },  
  feedback_question: {
    id: 'messages.feedback_question'
  },  
  rejoin_call: {
    id: 'button_actions_cta_rejoin_call'
  },
  messages_nps_feedback: {
    id: 'messages_nps_feedback'
  },
  button_actions_cta_submit: {
    id: 'button_actions_cta_submit'
  },
  messages_nps_negative_feedback: {
    id: 'messages_nps_negative_feedback'
  },
  messages_nps_positive_feedback: {
    id: 'messages_nps_positive_feedback'
  },
  messages_nps_comment: {
    id: 'messages_nps_comment'
  },
  messages_csat_feedback: {
    id: 'messages_csat_feedback',
  },
  messages_csat_negative_feedback: {
    id: 'messages_csat_negative_feedback',
  },
  messages_csat_positive_feedback: {
    id: 'messages_csat_positive_feedback',
  },
  messages_csat_comment: {
    id: 'messages_csat_comment',
  },  
  optional: {
    id: 'adverb_optional'
  },
  contact_error: {
    id: "messages.contact_error",
  },
  messages_contact_text_notification_modal_thanks: {
    id: "messages_contact_text_notification_modal_thanks",
  },
});
