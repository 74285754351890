import { defineMessages } from 'react-intl';

export const errorPageMessages = defineMessages({
  contact_header: {
    id: 'messages.contact_header'
  },  
  contact_store_busy: {
    id: 'messages_contact_store_busy'
  },
  contact_host_busy: {
    id: 'messages_contact_host_busy'
  },    
  sorry_closed_header: {
    id: 'messages.sorry_closed_header'
  },  
  contact_error: {
    id: 'messages.contact_error'
  },  
  call_link_error: {
    id: 'messages.call_link_error'
  },  
  contact_text: {
    id: 'messages_contact_text'
  },  
  contact_text_host_busy: {
    id: 'messages_contact_text_host_busy'
  },

  contact_host_busy_scheduled: {
    id: 'messages_contact_host_busy_scheduled'
  },
  contact_host_busy_scheduled_early: {
    id: 'messages_contact_host_busy_scheduled_early'
  },
  contact_host_busy_scheduled_late: {
    id: 'messages_contact_host_busy_scheduled_late'
  },
  contact_text_host_busy_scheduled: {
    id: 'messages_contact_text_host_busy_scheduled'
  },
  scheduled_call_start_at: {
    id: 'scheduled_call_start_at'
  },
  scheduled_call_start_at_late: {
    id: 'scheduled_call_start_at_late'
  },

  email: {
    id: 'nouns.email'
  },  
  contact_helper_text: {
    id: 'messages.contact_helper_text'
  },  
  contact_helper_text_1: {
    id: 'messages.contact_helper_text_1'
  },
  cta_send: {
    id: 'button_actions.cta_send'
  },
  cta_done: {
    id: 'button_actions.cta_done'
  },
  cta_close: {
    id: "button_actions_cta_close",
  },
  email_error: {
    id: 'messages.email_error'
  },
  email_invalid: {
    id: 'messages.email_invalid'
  },
  name_error: {
    id: 'messages_name_error'
  },
  email_name_error: {
    id: 'messages_email_name_error'
  },
  or_schedule_later: {
    id: 'messages.or_schedule_later'
  },
  textarea_label: {
    id: 'nouns_message'
  },
  host_noun: {
    id: 'nouns_host'
  },
  nouns_today: {
    id: 'nouns.today'
  },
  nouns_tomorrow: {
    id: 'nouns.tomorrow'
  },
  messages_contact_text_notification_modal_contact: {
    id: "messages_contact_text_notification_modal_contact",
  },
});
