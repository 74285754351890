import React from "react";
import { FilledButton } from "../buttons/buttons";
import "./notificationModal.scss";

export const NotificationModal = ({ text, btnLabel, isOpen, onClose }) => {
      if (!isOpen) {
            return null;
      }
      return (
            <div className="notification-modal">
                  <div className="notification-dialog">
                        <div className="notification-alert">
                              <div className="notification-alert-dialog">
                                    <div className="dialog-content__root">
                                          <p>{text}</p>
                                    </div>
                                    <div className="dialog-actions__root">
                                          <FilledButton className="notification-accept-button" onClick={onClose}>
                                                {btnLabel}
                                          </FilledButton>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      );
};